<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width="this.width"
    :height="this.height"
    :viewBox="this.customIcon.viewBox"
    aria-labelledby=""
    role="presentation">
    <path v-for="(path, pathIndex) in this.customIcon.paths" :d="path" :key="pathIndex" :fill="getColor(pathIndex)"/>
  </svg>
</template>

<script>
import CustomIcon from './CustomIcon'

export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    colors: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getColor (index) {
      return this.customColors[index]
    }
  },
  data () {
    return {
      customIcon: null,
      customColors: []
    }
  },
  created: function () {
    this.customIcon = new CustomIcon(this.icon)

    if (this.customIcon.paths === undefined) {
      this.$emit('unknownIcon')
    }

    if (this.colors.length === 0) {
      this.customColors = this.customIcon.colors
    } else {
      this.customColors = this.colors
    }
  }
}
</script>

export default {
  interpolateRainbow (c0, c1, f) {
    c0 = c0.match(/.{1,2}/g).map((oct) => parseInt(oct, 16) * (1 - f))
    c1 = c1.match(/.{1,2}/g).map((oct) => parseInt(oct, 16) * f)
    const ci = [0, 1, 2].map(i => Math.min(Math.round(c0[i] + c1[i]), 255))
    return '#' + ci.reduce((a, v) => ((a << 8) + v), 0).toString(16).padStart(6, '0')
  },
  hexlight (hex, light) {
    const hsl = this.hexToHsl(hex)
    return this.hslToHex(hsl[0], hsl[1], light)
  },
  hexHue (hex, hue) {
    const hsl = this.hexToHsl(hex)
    return this.hslToHex(hue, hsl[1], hsl[2])
  },
  hexToHsl (hex) {
    const rgb = this.hexToRgb(hex)
    return this.rgbToHsl(rgb[0], rgb[1], rgb[2])
  },
  hslToHex (h, s, l) {
    const rgb = this.hslToRgb(h, s, l)
    return this.rgbToHex(rgb[0], rgb[1], rgb[2])
  },
  rgbToHsl (r, g, b) {
    r = r / 255
    g /= 255
    b /= 255
    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    const d = max - min
    let h
    if (d === 0) h = 0
    else if (max === r) h = (g - b) / d % 6
    else if (max === g) h = (b - r) / d + 2
    else if (max === b) h = (r - g) / d + 4
    const l = (min + max) / 2
    const s = d === 0 ? 0 : d / (1 - Math.abs(2 * l - 1))
    return [h * 60, s, l]
  },
  hslToRgb (h, s, l) {
    const c = (1 - Math.abs(2 * l - 1)) * s
    const hp = h / 60.0
    const x = c * (1 - Math.abs((hp % 2) - 1))
    let rgb1
    if (isNaN(h)) rgb1 = [0, 0, 0]
    else if (hp <= 1) rgb1 = [c, x, 0]
    else if (hp <= 2) rgb1 = [x, c, 0]
    else if (hp <= 3) rgb1 = [0, c, x]
    else if (hp <= 4) rgb1 = [0, x, c]
    else if (hp <= 5) rgb1 = [x, 0, c]
    else if (hp <= 6) rgb1 = [c, 0, x]
    const m = l - c * 0.5
    return [
      Math.round(255 * (rgb1[0] + m)),
      Math.round(255 * (rgb1[1] + m)),
      Math.round(255 * (rgb1[2] + m))
    ]
  },
  hexToRgb (hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b
    })

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null
  },
  rgbToHex (r, g, b) {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
  },
  formatAsRGB (rgb) {
    return 'rgb(' + rgb[0] + ', ' + rgb[1] + ', ' + rgb[2] + ')'
  }
}

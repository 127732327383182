<template>
  <div class="layout-wrapper layout-2">
    <div class="layout-inner">
      <app-layout-sidenav-bo v-if="!isNotBackOffice"/>

      <div class="layout-container">
        <app-layout-navbar ref="navbar" />

        <div class="layout-content">
          <div class="router-transitions router-container" :class="!isNotBackOffice && !isCompactFeature ? 'container-fluid container-p-y':''">
            <router-view :key="$route.fullPath" allow="autoplay" @takeOff="takeOff" @landing="landing"/>
          </div>
        </div>
      </div>
    </div>
    <div class="layout-overlay" @click="closeSidenav"></div>
  </div>
</template>

<script>
import LayoutNavbar from './LayoutNavbar'
import LayoutSidenavBo from './LayoutSidenavBo'
import { takeOffMixin } from '@/mixins/TakeOffMixin.js'

export default {
  name: 'app-layout',
  components: {
    'app-layout-navbar': LayoutNavbar,
    'app-layout-sidenav-bo': LayoutSidenavBo
  },
  mixins: [takeOffMixin],
  computed: {
    isNotBackOffice () {
      return this.$router.currentRoute.name === 'live' ||
        this.$router.currentRoute.name === 'replay' ||
        this.$router.currentRoute.name === 'preflights' ||
        this.$router.currentRoute.name === 'addPreflight' ||
        this.$router.currentRoute.name === 'editPreflight' ||
        this.$router.currentRoute.name === 'editPostflight' ||
        this.$router.currentRoute.name === 'procedures' ||
        this.$router.currentRoute.name === 'editProcedure'
    },
    isCompactFeature () {
      return this.$router.currentRoute.name === 'procedureAutomations'
    }
  },
  mounted () {
    this.layoutHelpers.init()
    this.layoutHelpers.update()
    this.layoutHelpers.setAutoUpdate(true)
  },
  beforeDestroy () {
    this.layoutHelpers.destroy()
  },
  data () {
    return {
      takeOffProcess: null,
      routineDelay: 15000
    }
  },
  methods: {
    closeSidenav () {
      this.layoutHelpers.setCollapsed(true)
    },
    takeOff () {
      // Start process
      if (this.takeOffProcess === null) {
        this.takeOffProcess = setTimeout(() => this.takeOffRoutine())
      }
    },
    landing () {
      if (!this.isStorageTakeOff()) {
        // UI drone DOWN
        this.$refs.navbar.setDroneActivity(false)
        this.takeOffProcess = null
      }
    },
    takeOffRoutine () {
      const takeOffs = this.getStorageTakeOffs()
      const now = new Date().getTime()

      takeOffs.forEach(t => {
        if (now >= t.last + this.routineDelay) {
          // Update local storage
          this.setStorageTakeOff(t.id)
        }
      })

      if (takeOffs.length !== 0) {
        this.takeOffProcess = setTimeout(() => this.takeOffRoutine(), this.routineDelay)
        // UI drone UP
        this.$refs.navbar.setDroneActivity(true)
      } else {
        // UI drone DOWN
        this.$refs.navbar.setDroneActivity(false)
      }
    }
  },
  created () {
    if (Notification.permission === 'default') {
      Notification.requestPermission()
    }

    // New tab: manage existing takeOfs
    if (this.isStorageTakeOff()) {
      this.takeOffProcess = setTimeout(() => this.takeOffRoutine())
    }
  }
}
</script>

<style>
.router-container {
  flex-direction: column;
  display: flex;
  flex: 1 1 auto;
}
</style>

import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import globals from '@/globals'

import Layout from '@/layout/Layout'
import VueClipboard from 'vue-clipboard2'
import { store } from '../store'

Vue.use(Router)
Vue.use(Meta)
Vue.use(VueClipboard)

const router = new Router({
  base: '/',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'root',
      beforeEnter (to, from, next) {
        if (store.state.token) {
          next('/backoffice/' + localStorage.getItem('homepage'))
        } else {
          next('/login')
        }
      }
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: () => import('@/components/Maintenance')
    },
    {
      path: '/backoffice',
      name: 'backoffice',
      component: Layout,
      beforeEnter (to, from, next) {
        if (store.state.token) {
          next()
        } else {
          next('/login')
        }

        // Disable routes if preflight module is disabled
        if (process.env.VUE_APP_PREFLIGHT_MODULE === 'NO' && (to.name === 'preflights' || to.name === 'addPreflight')) {
          next('/backoffice/live')
        }
      },
      children: [
        {
          path: 'dashboard',
          name: 'dashboard',
          component: () => import('@/components/Dashboard')
        },
        {
          path: 'users',
          name: 'users',
          component: () => import('@/components/Users')
        },
        {
          path: 'users/add',
          name: 'addUser',
          component: () => import('@/components/MyAccountAddEdit')
        },
        {
          path: 'users/edit/:id',
          name: 'editUser',
          component: () => import('@/components/MyAccountAddEdit')
        },
        {
          path: 'account',
          name: 'account',
          component: () => import('@/components/MyAccountAddEdit')
        },
        {
          path: 'procedureautomations',
          name: 'procedureAutomations',
          component: () => import('@/components/ProcedureAutomations')
        },
        {
          path: 'procedureclassifications',
          name: 'procedureClassifications',
          component: () => import('@/components/ProcedureClassifications')
        },
        {
          path: 'groups',
          name: 'groups',
          component: () => import('@/components/Groups')
        },
        {
          path: 'groups/add',
          name: 'addGroup',
          component: () => import('@/components/GroupAddEdit')
        },
        {
          path: 'groups/edit/:id',
          name: 'editGroup',
          component: () => import('@/components/GroupAddEdit')
        },
        {
          path: 'airspaces',
          name: 'airspaces',
          component: () => import('@/components/Airspaces')
        },
        {
          path: 'airspaces/add',
          name: 'addAirspace',
          component: () => import('@/components/MyAirspaceAddEdit')
        },
        {
          path: 'airspaces/edit/:id',
          name: 'editAirspace',
          component: () => import('@/components/MyAirspaceAddEdit')
        },
        {
          path: 'airspace',
          name: 'airspace',
          component: () => import('@/components/MyAirspaceAddEdit')
        },
        {
          path: 'pilots',
          name: 'pilots',
          component: () => import('@/components/Pilots')
        },
        {
          path: 'pilots/user/:userId',
          name: 'userPilots',
          component: () => import('@/components/Pilots')
        },
        {
          path: 'pilots/add',
          name: 'addPilot',
          component: () => import('@/components/PilotAddEdit')
        },
        {
          path: 'pilots/add/:userId',
          name: 'addUserPilot',
          component: () => import('@/components/PilotAddEdit')
        },
        {
          path: 'pilots/edit/:id',
          name: 'editPilot',
          component: () => import('@/components/PilotAddEdit')
        },
        {
          path: 'pilots/edit/:userId/:id',
          name: 'editUserPilot',
          component: () => import('@/components/PilotAddEdit')
        },
        {
          path: 'machines',
          name: 'machines',
          component: () => import('@/components/Machines')
        },
        {
          path: 'machines/user/:userId',
          name: 'userMachines',
          component: () => import('@/components/Machines')
        },
        {
          path: 'machines/add',
          name: 'addMachine',
          component: () => import('@/components/MachineAddEdit')
        },
        {
          path: 'machines/add/:userId',
          name: 'addUserMachine',
          component: () => import('@/components/MachineAddEdit')
        },
        {
          path: 'machines/edit/:id',
          name: 'editMachine',
          component: () => import('@/components/MachineAddEdit')
        },
        {
          path: 'machines/edit/:userId/:id',
          name: 'editUserMachine',
          component: () => import('@/components/MachineAddEdit')
        },
        {
          path: 'payments/user/:userId',
          name: 'userPayments',
          component: () => import('@/components/Payments')
        },
        {
          path: 'payments/edit/:userId/:id',
          name: 'editUserPayment',
          component: () => import('@/components/PaymentEdit')
        },
        {
          path: 'devices',
          name: 'devices',
          component: () => import('@/components/Devices')
        },
        {
          path: 'trackers',
          name: 'trackers',
          component: () => import('@/components/Trackers')
        },
        {
          path: 'trackers/add',
          name: 'addTracker',
          component: () => import('@/components/TrackerAddEdit')
        },
        {
          path: 'trackers/edit/:id',
          name: 'editTracker',
          component: () => import('@/components/TrackerAddEdit')
        },
        {
          path: 'whitelistmachine',
          name: 'whitelistmachine',
          component: () => import('@/components/WhitelistMachine')
        },
        {
          path: 'validateattachements',
          name: 'validateAttachements',
          component: () => import('@/components/ValidateAttachements')
        },
        {
          path: 'alerts',
          name: 'alerts',
          component: () => import('@/components/Alerts')
        },
        {
          path: 'alerts/add',
          name: 'addAlert',
          component: () => import('@/components/AlertAddEdit')
        },
        {
          path: 'alerts/edit/:id',
          name: 'editAlert',
          component: () => import('@/components/AlertAddEdit')
        },
        {
          path: 'documents',
          name: 'documents',
          component: () => import('@/components/Documents')
        },
        {
          path: 'documents/add',
          name: 'addDocument',
          component: () => import('@/components/DocumentAddEdit')
        },
        {
          path: 'documents/edit/:id',
          name: 'editDocument',
          component: () => import('@/components/DocumentAddEdit')
        },
        {
          path: 'dronemodels',
          name: 'droneModels',
          component: () => import('@/components/DroneModels')
        },
        {
          path: 'dronemodels/add',
          name: 'addDroneModel',
          component: () => import('@/components/DroneModelAddEdit')
        },
        {
          path: 'dronemodels/edit/:id',
          name: 'editDroneModel',
          component: () => import('@/components/DroneModelAddEdit')
        },
        {
          path: 'live',
          name: 'live',
          component: () => import('@/components/Map')
        },
        {
          path: 'preflights',
          name: 'preflights',
          component: () => import('@/components/PreFlights')
        },
        {
          path: 'preflights/add',
          name: 'addPreflight',
          component: () => import('@/components/PreFlightAddEdit')
        },
        {
          path: 'preflights/edit/:id',
          name: 'editPreflight',
          component: () => import('@/components/PreFlightAddEdit')
        },
        {
          path: 'postflights/:id',
          name: 'editPostflight',
          component: () => import('@/components/PostFlightAddEdit')
        },
        {
          path: 'replay',
          name: 'replay',
          component: () => import('@/components/Replay')
        },
        {
          path: 'myagreement',
          name: 'myAgreement',
          component: () => import('@/components/MyAgreement')
        },
        {
          path: 'procedures',
          name: 'procedures',
          component: () => import('@/components/Procedures')
        },
        {
          path: 'procedure/edit/:id',
          name: 'editProcedure',
          component: () => import('@/components/ProcedureEdit')
        }
      ]
    },
    {
      path: '/attachements/read/:type/:id',
      name: 'readAttachement',
      component: () => import('@/components/Attachement')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/components/Login/Login')
    },
    {
      path: '/signup',
      name: 'signUp',
      component: () => import('@/components/Login/SignUp')
    },
    {
      path: '/activate-account/:token',
      name: 'activateAccount',
      component: () => import('@/components/Login/Login')
    },
    {
      path: '/forgotten-password',
      name: 'forgottenPassword',
      component: () => import('@/components/Login/ForgottenPassword')
    },
    {
      path: '/reset-password/:token',
      name: 'resetPassword',
      component: () => import('@/components/Login/ResetPassword')
    },
    {
      path: '/change-password',
      name: 'changePassword',
      component: () => import('@/components/Login/ChangePassword')
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter (to, from, next) {
        store.dispatch('logout')
        next('/login')
      }
    }]
})

router.afterEach(() => {
  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})

export default router

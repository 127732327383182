<template>
  <sidenav :orientation="orientation" :class="curClasses">
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">
      <sidenav-router-link icon="ion ion-md-calculator" :to="{ name: 'dashboard'}">{{ $t("nav.dashboard") }}</sidenav-router-link>

      <!-- Administrators -->
      <sidenav-router-link icon="ion ion-md-contacts" :to="{ name: 'users'}" :exact="true" v-if="$can('ROLE_USER_LIST_ADMIN')">{{ $t("nav.users") }}</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-lock" :to="{ name: 'groups'}" :exact="true" v-if="$can('ROLE_GROUP_LIST_ADMIN')">{{ $t("nav.groups") }}</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-map" :to="{ name: 'airspaces'}" :exact="true" v-if="$can('ROLE_AIRSPACE_LIST_ADMIN')">{{ $t("nav.airspaces") }}</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-battery-charging" :to="{ name: 'trackers'}" :exact="true" v-if="$can('ROLE_TRACKER_LIST_ADMIN')">{{ $t("nav.trackers") }}</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-paper" :to="{ name: 'whitelistmachine'}" :exact="true" v-if="$can('ROLE_USER_EDIT_ADMIN')">{{ $t("nav.whitelistMachine") }}</sidenav-router-link>

      <!-- Validators -->
      <sidenav-router-link icon="ion ion-md-search" :to="{ name: 'validateAttachements'}" :exact="true" v-if="$can('ROLE_ATTACHEMENT_VALIDATE')">{{ $t("nav.validateAttachements") }}</sidenav-router-link>

      <!-- Administrators -->
      <sidenav-router-link icon="ion ion-md-alert" :to="{ name: 'alerts'}" :exact="true" v-if="$can('ROLE_ALERT_LIST_ADMIN')">{{ $t("nav.alerts") }}</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-paper-plane" :to="{ name: 'droneModels'}" :exact="true">{{ $t("nav.droneModels") }}</sidenav-router-link>

      <!-- Operators -->
      <sidenav-router-link icon="ion ion-md-clipboard" :to="{ name: 'myAgreement'}" :exact="true" v-if="displayMyAgreement()">{{ $t("nav.myAgreement") }}</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-person" :to="{ name: 'account'}" :exact="true" v-if="!$can('ROLE_SUPERVISION_PILOT') || $can('ROLE_SUPERVISION_ACCOUNT_EDIT')">{{ $can('ROLE_SUPERVISION_PILOT') ? $t("nav.parentAccount") : $t("nav.myAccount") }}</sidenav-router-link>

      <!-- Airspace Managers -->
      <sidenav-router-link icon="ion ion-md-desktop" :to="{ name: 'airspace'}" :exact="true" v-if="$can('ROLE_PREFLIGHTPROCEDURE_EDIT')">{{ $t("nav.myAirspace") }}</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-switch" :to="{ name: 'procedureAutomations'}" :exact="true" v-if="$can('ROLE_PROCEDUREAUTOMATION_LIST')">{{ $t("nav.procedureAutomations") }}</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-podium" :to="{ name: 'procedureClassifications'}" :exact="true" v-if="$can('ROLE_PROCEDURECLASSIFICATION_LIST')">{{ $t("nav.procedureClassifications") }}</sidenav-router-link>

      <!-- Operators -->
      <sidenav-router-link icon="ion ion-md-person" :to="{ name: 'editPilot', params: { id: relatedPilot }}" :exact="true" v-if="$can('ROLE_SUPERVISION_PILOT')">{{ $t("nav.myAccount") }}</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-headset" :to="{ name: 'pilots'}" :exact="true" v-if="$can('ROLE_PILOT_LIST')">{{ $t("nav.pilots") }}</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-paper-plane" :to="{ name: 'machines'}" :exact="true" v-if="$can('ROLE_MACHINE_LIST')">{{ $t("nav.machines") }}</sidenav-router-link>

      <sidenav-router-link icon="ion ion-md-watch" :to="{ name: 'devices'}" :exact="true" v-if="displayDevices()">{{ $t("nav.devices") }}</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-book" :to="{ name: 'documents'}" :exact="true" v-if="$can('ROLE_DOCUMENTATION_LIST')">{{ $t("nav.documents") }}</sidenav-router-link>
    </div>
  </sidenav>
</template>

<script>
import { Sidenav, SidenavRouterLink } from '@/vendor/libs/sidenav'
import UserStatus from '@/enums/UserStatus'

export default {
  name: 'app-layout-sidenav',
  components: {
    Sidenav,
    SidenavRouterLink
  },
  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },
  computed: {
    curClasses () {
      let bg = this.layoutSidenavBg

      if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + (
        this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0'
      )
    },
    relatedPilot () {
      return localStorage.getItem('supervision')
    }
  },
  methods: {
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },
    isMenuOpen (url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },
    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    },
    displayMyAgreement () {
      return process.env.VUE_APP_SIGNUP_MODULE === 'RESTRICTED' &&
        localStorage.getItem('status') !== UserStatus.STATUS_NO_NEED_AGREEMENT &&
        (!this.$can('ROLE_SUPERVISION_PILOT') || this.$can('ROLE_SUPERVISION_ACCOUNT_EDIT'))
    },
    displayDevices () {
      const email = localStorage.getItem('email')
      if (email) return email.includes('@airspacedrone.com')
      else return false
    }
  }
}
</script>

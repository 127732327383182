// Sources from Traffic back entity
const SOURCE_ADSB = 'ab'
const SOURCE_OGN = 'og'
const SOURCE_WIFI = 'wi'
const SOURCE_AEROSCOPE = 'as'
const SOURCE_ASD = 'ad'
const SOURCE_MOBILE = 'mo'
const SOURCE_STATION = 'st'

export default { SOURCE_ADSB, SOURCE_OGN, SOURCE_WIFI, SOURCE_AEROSCOPE, SOURCE_ASD, SOURCE_MOBILE, SOURCE_STATION }

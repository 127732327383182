// Types from Tracker back entity
const TYPE_HELI = 'heli'
const TYPE_DRONE = 'drone'
const TYPE_DRONEHEXA = 'droneHexa'
const TYPE_DRONEOCTO = 'droneOcto'
const TYPE_WING = 'wing'
const TYPE_USER = 'user'
const TYPE_BOAT = 'boat'
const TYPE_CAR = 'car'
const TYPE_PLANE = 'plane'
const TYPE_SPOT = 'spot'
const TYPE_STATION = 'station'

const TYPE_HOME = 'home' // Only front

export default { TYPE_HELI, TYPE_DRONE, TYPE_DRONEHEXA, TYPE_DRONEOCTO, TYPE_WING, TYPE_USER, TYPE_BOAT, TYPE_CAR, TYPE_PLANE, TYPE_SPOT, TYPE_STATION, TYPE_HOME }

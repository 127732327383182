import Vue from 'vue'
import i18n from './vue-i18n'
import axios from 'axios'
import { store } from '@/store'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 120000
})

const token = localStorage.getItem('token')
if (token) {
  instance.defaults.headers.common.Authorization = 'Bearer ' + token
}

instance.interceptors.request.use(
  function (config) {
    // Check if request is other than login and gjrt
    const relativeUrl = (config.url.replace(/^.*\/\/[^/]+/, ''))

    if (!relativeUrl.includes('login') && !relativeUrl.includes('gjrt')) {
      const expiredAtM = parseInt(localStorage.getItem('expiredAtM'))
      const now = new Date()
      if (expiredAtM - now.getTime() < 30 * 60 * 1000) {
        store.dispatch('gjrt')
      }
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  })

instance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    let message = 'errors.unexpected'
    if (error.response && error.response.data && error.response.data.message) {
      message = error.response.data.message
    } else if (error.message) {
      message = error.message
    }

    Vue.notify({
      group: 'main',
      title: i18n.t('errors.error'),
      duration: 12000,
      type: 'bg-warning text-body',
      text: i18n.t(message)
    })

    return Promise.reject(error)
  })

export default instance

import Vue from 'vue'
import App from './App'
import router from './router'
import { abilitiesPlugin } from '@casl/vue'
import { store, ability } from './store'

import BootstrapVue from 'bootstrap-vue'

import globals from './globals'
import Popper from 'popper.js'

import Vuelidate from 'vuelidate'

import i18n from './vue-i18n'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import Notifications from 'vue-notification'
import ColorCode from '@/colors.js'

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

Vue.use(BootstrapVue, {
  BTooltip: {
    delay: {
      show: 1000,
      hide: 100
    }
  }
})
Vue.use(Vuelidate)
Vue.use(abilitiesPlugin, ability)
Vue.use(Notifications)
Vue.use(Loading, {
  color: ColorCode.gen.loader,
  opacity: 0.25
})

// Global RTL flag
Vue.mixin({
  data: globals
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

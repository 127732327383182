export const featureMixin = {

  methods: {
    getFeatures () {
      const features = []
      features.push({ to: 'dashboard', label: 'nav.dashboard' })

      if (process.env.VUE_APP_PREFLIGHT_MODULE === 'YES') {
        // Airspace manager & controller
        if (this.$can('ROLE_PREFLIGHTPROCEDURE_LIST')) {
          features.push({ to: 'procedures', label: 'nav.procedures' })
        }

        // Pre-flight
        if (this.$can('ROLE_PREFLIGHT_LIST') || this.$can('ROLE_PREFLIGHT_LIST_ADMIN')) {
          features.push({ to: 'preflights', label: 'nav.preFlight' })
        }
      }

      features.push({ to: 'live', label: 'nav.map' })

      if (this.$can('ROLE_JOURNEY_LIST') || this.$can('ROLE_JOURNEY_LIST_ADMIN')) {
        features.push({ to: 'replay', label: 'nav.analysis' })
      }

      return features
    }
  }
}

import { Ability } from '@casl/ability'

export const ability = new Ability()

export const abilityPlugin = (store) => {
  return store.subscribe((mutation) => {
    const rules = []

    switch (mutation.type) {
      case 'setAuthRoles':
        // Build very simple rules for CASL
        mutation.payload.roles.forEach(role => {
          rules.push({
            action: role,
            subject: 'all'
          })
        })

        ability.update(rules)
        break
      case 'clearAuthData':
        ability.update([])
        break
    }
  })
}

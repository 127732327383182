var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{staticClass:"layout-navbar container-p-x",attrs:{"toggleable":"lg","variant":_vm.getLayoutNavbarBg()}},[(_vm.sidenavToggle && !_vm.isNotBackOffice)?_c('b-navbar-nav',{staticClass:"mr-4"},[_c('a',{staticClass:"nav-item nav-link px-0 ml-2 ml-lg-0",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.toggleSidenav}},[_c('i',{staticClass:"ion ion-md-menu text-large align-middle"})])]):_vm._e(),_vm._v(" "),(!_vm.hiddenBrand)?_c('div',[_c('img',{attrs:{"height":"50","src":"/logo/logo_asd_small.png"}})]):_vm._e(),_vm._v(" "),_c('b-navbar-toggle',{attrs:{"target":"app-layout-navbar"}}),_vm._v(" "),_c('b-collapse',{attrs:{"is-nav":"","id":"app-layout-navbar"}},[_c('b-navbar-nav',{staticClass:"w-100 d-flex justify-content-between bd-highlight align-items-center"},[_c('div',{staticClass:"col p-2 bd-highlight text-center"},[_c('ul',{staticClass:"nav tabs-alt nav-tabs justify-content-center border-0"},_vm._l((_vm.navbarButtons),function(navbarButton,index){return _c('li',{key:index,staticClass:"nav-item"},[_c('router-link',{class:_vm.isNavButtonActive(navbarButton.to),attrs:{"custom":"","to":{ name: navbarButton.to }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('b-dd-item',{staticClass:"p-0",attrs:{"href":href}},[_vm._v(_vm._s(_vm.$t(navbarButton.label)))])]}}],null,true)})],1)}),0)]),_vm._v(" "),(_vm.isDroneActivity)?_c('div',{staticClass:"px-2 bd-highlight text-right"},[_c('div',{staticClass:"spinner-grow spinner-grow-sm spinner-rec text-danger",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t("preFlight.takeOff")))])]),_vm._v(" "),_c('app-baseIcon',{attrs:{"icon":"drone","width":40,"height":40,"colors":[_vm.colorTakeOff]}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"px-2 bd-highlight text-right"},[_c('div',{staticClass:"float-left"},[_c('b-nav-item-dropdown',[_c('template',{slot:"button-content"},[_c('span',{staticClass:"d-inline-flex flex-lg-row-reverse align-items-center align-middle"},[_c('b-avatar',{attrs:{"variant":"secondary","text":_vm.initials}}),_vm._v(" "),_c('span',{staticClass:"px-1 mr-lg-2 ml-2 ml-lg-0"},[_vm._v(_vm._s(_vm.username))])],1)]),_vm._v(" "),_c('router-link',{attrs:{"custom":"","to":{ name: 'dashboard'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('b-dd-item',{attrs:{"href":href}},[_c('i',{staticClass:"ion ion-md-calculator text-primary"}),_vm._v("   "+_vm._s(_vm.$t("nav.dashboard")))])]}}])}),_vm._v(" "),_c('b-dd-divider'),_vm._v(" "),(!_vm.$can('ROLE_SUPERVISION_PILOT') || _vm.$can('ROLE_SUPERVISION_ACCOUNT_EDIT'))?_c('router-link',{attrs:{"custom":"","to":{ name: 'account'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('b-dd-item',{attrs:{"href":href}},[_c('i',{staticClass:"ion ion-md-person text-primary"}),_vm._v("   "+_vm._s(_vm.$can('ROLE_SUPERVISION_PILOT') ? _vm.$t("nav.parentAccount") : _vm.$t("nav.myAccount")))])]}}],null,false,10850282)}):_vm._e(),_vm._v(" "),(_vm.$can('ROLE_PREFLIGHTPROCEDURE_EDIT'))?_c('router-link',{attrs:{"custom":"","to":{ name: 'airspace', params: { id: _vm.relatedPilot }}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('b-dd-item',{attrs:{"href":href}},[_c('i',{staticClass:"ion ion-md-desktop text-primary"}),_vm._v("   "+_vm._s(_vm.$t("nav.myAirspace")))])]}}],null,false,2720079514)}):_vm._e(),_vm._v(" "),(_vm.$can('ROLE_PROCEDUREAUTOMATION_LIST'))?_c('router-link',{attrs:{"custom":"","to":{ name: 'procedureAutomations', params: { id: _vm.relatedPilot }}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('b-dd-item',{attrs:{"href":href}},[_c('i',{staticClass:"ion ion-md-switch text-primary"}),_vm._v("   "+_vm._s(_vm.$t("nav.procedureAutomations")))])]}}],null,false,262872689)}):_vm._e(),_vm._v(" "),(_vm.$can('ROLE_PROCEDURECLASSIFICATION_LIST'))?_c('router-link',{attrs:{"custom":"","to":{ name: 'procedureClassifications', params: { id: _vm.relatedPilot }}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('b-dd-item',{attrs:{"href":href}},[_c('i',{staticClass:"ion ion-md-podium text-primary"}),_vm._v("   "+_vm._s(_vm.$t("nav.procedureClassifications")))])]}}],null,false,3606347232)}):_vm._e(),_vm._v(" "),(_vm.$can('ROLE_SUPERVISION_PILOT'))?_c('router-link',{attrs:{"custom":"","to":{ name: 'editPilot', params: { id: _vm.relatedPilot }}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('b-dd-item',{attrs:{"href":href}},[_c('i',{staticClass:"ion ion-md-person text-primary"}),_vm._v("   "+_vm._s(_vm.$t("nav.myAccount")))])]}}],null,false,678239202)}):_vm._e(),_vm._v(" "),(_vm.$can('ROLE_PILOT_LIST'))?_c('router-link',{attrs:{"custom":"","to":{ name: 'pilots'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('b-dd-item',{attrs:{"href":href}},[_c('i',{staticClass:"ion ion-md-headset text-primary"}),_vm._v("   "+_vm._s(_vm.$t("nav.pilots")))])]}}],null,false,3648305781)}):_vm._e(),_vm._v(" "),(_vm.$can('ROLE_MACHINE_LIST'))?_c('router-link',{attrs:{"custom":"","to":{ name: 'machines'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('b-dd-item',{attrs:{"href":href}},[_c('i',{staticClass:"ion ion-md-paper-plane text-primary"}),_vm._v("   "+_vm._s(_vm.$t("nav.machines")))])]}}],null,false,1728531321)}):_vm._e(),_vm._v(" "),_c('b-dd-divider'),_vm._v(" "),_c('router-link',{attrs:{"custom":"","to":{ name: 'logout'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('b-dd-item',{attrs:{"href":href}},[_c('i',{staticClass:"ion ion-md-log-out text-danger"}),_vm._v("   "+_vm._s(_vm.$t("nav.logout")))])]}}])})],2)],1),_vm._v(" "),_c('div',{staticClass:"float-right"},[_c('div',{staticClass:"ml-4 d-none d-lg-block"},[_c('img',{attrs:{"height":"50","src":"/logo/logo_env_small.png"}})])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
const AMERICAN_BLUE = '#3B3B6D'

const BLACK_00 = '#000000'
const BLACK_13 = '#222222'
const BLACK_27 = '#444444'

const BLUE_LIGHT_30 = '#0FC0FC' // Fix the number

const BLUE_50 = '#00BFFF'
const BLUE_57 = '#26B4FF'
const BLUE_DEEP_45 = '#0039E6'
const BLUE_DEEP_57 = '#265EFF'

const PASTEL_PINK = '#DEA5A4'

const BLUE_GREEN_25 = '#008080'

const BROWN_35 = '#916020'
const BROWN_45 = '#AF7426'

const GRAY_32 = '#4E5155'
const GRAY_55 = '#888C91'

const GREEN_33 = '#00A900'
const GREEN_37 = '#02BC77' // Fix the number
const GREEN_40 = '#00CC00'
const GREEN_50 = '#00FF00'

const ORANGE_40 = '#C46210' // Fix the number
const ORANGE_50 = '#FF7F00'
const ORANGE_65 = '#FFA64D'

const RED_40 = '#CC0000'
const RED_50 = '#FF0000'
const RED_65 = '#FF4D4D'
const RED_70 = '#FF6666'
const RED_80 = '#FF9999'

const PURPLE_LIGHT_45 = '#E600E6'
const PURPLE_LIGHT_50 = '#FF00FF'
const PURPLE_65 = '#DA70D6'

const WHITE = '#FFFFFF'

const YELLOW_40 = '#CCC900'
const YELLOW_45 = '#FFCC00' // Fix the number
const YELLOW_50 = '#FFFB00'

// Boostrap colors
const BOOSTRAP_PRIMARY = '#007BFF'
const BOOSTRAP_SECONDARY = '#6C757D'
const BOOSTRAP_SUCCESS = '#28A745'
const BOOSTRAP_DANGER = '#DC3545'
const BOOSTRAP_WARNING = '#FFC107'
const BOOSTRAP_INFO = '#17A2B8'
const BOOSTRAP_DARK = '#181b21'
const BOOSTRAP_PURPLE = '#6f42c1' // Bootsrap 5

export default {
  chart: {
    border: BLUE_57,
    background: BLUE_57,
    height: BLUE_57,
    heightAlert: RED_50,
    heightCorrected: BLUE_DEEP_57,
    groundGradientStart: GREEN_33,
    groundGradientEnd: BROWN_35
  },
  gen: {
    loader: BLUE_57
  },
  journey: {
    stroke: BLUE_57
  },
  alert: {
    default: PURPLE_LIGHT_50
  },
  weather: {
    VFR: GREEN_40,
    MVFR: BLUE_DEEP_45,
    IFR: RED_50,
    LIFR: PURPLE_LIGHT_45,
    UNKNOWN: GRAY_55,
    stroke: BLACK_13,
    halo: BLACK_27
  },
  preflight: {
    status: {
      draft: BOOSTRAP_SECONDARY,
      complete: BOOSTRAP_PRIMARY,
      toBeValidated: BOOSTRAP_WARNING,
      validated: BOOSTRAP_SUCCESS,
      archived: BOOSTRAP_DARK
    },
    overview: BLUE_GREEN_25,
    takeOff: BOOSTRAP_PURPLE,
    constraint: {
      highlight: RED_50,
      unlock: GREEN_37,
      urban: PURPLE_LIGHT_45,
      reserve: GREEN_40,
      road: ORANGE_50,
      obstacle: BLUE_LIGHT_30,
      rail: RED_40,
      aero: BLUE_57,
      frb: RED_70,
      power: BLUE_GREEN_25,
      notam: BROWN_45,
      antenna: PASTEL_PINK,
      elevation: BROWN_35,
      density: AMERICAN_BLUE,
      mco3d: ORANGE_40,
      default: PURPLE_65
    },
    live: GREEN_40,
    liveActivity: PURPLE_LIGHT_50,
    safetyZone: {
      exclusion: ORANGE_65,
      buffer: YELLOW_40,
      outOfView: RED_40,
      contingency: YELLOW_50,
      riskBuffer: RED_65
    }
  },
  procedure: {
    inProgress: BOOSTRAP_WARNING,
    ok: BOOSTRAP_SUCCESS,
    ko: BOOSTRAP_DANGER,
    disable: GRAY_32
  },
  procedureAutomation: {
    airspace: BLUE_DEEP_57
  },
  replay: {
    fullLineOverview: BLUE_57,
    fullLineShadow: GRAY_32,
    partialLine: BLUE_57,
    partialDot: BLUE_57,
    heatmap: {
      none: '#2166AC',
      veryLow: '#67A9CF',
      low: '#D1E5F0',
      medium: '#FDDBC7',
      high: '#EF8A62',
      max: '#B2182B'
    },
    preview: RED_50,
    violation: RED_50
  },
  spot: {
    fillOnBright: GRAY_32,
    strokeOnBright: WHITE,
    fillOnNotBright: WHITE,
    strokeOnNotBright: GRAY_32,

    circle: BLUE_50,
    waypoint: YELLOW_50,
    textDefault: GRAY_32,
    textStrokeDefault: WHITE,
    textBorderDefault: WHITE,
    textBorderOnBright: GRAY_32,

    violation: RED_50,
    outOfTime: PURPLE_65,
    unmanaged: ORANGE_50,
    managed: GREEN_40
  },
  vector: {
    stroke: GREEN_50,
    markerText: GREEN_50
  },
  nfz: {
    warning: YELLOW_50,
    autorization: ORANGE_50,
    restricted: RED_50,
    enhancedWarning: YELLOW_40,
    recommended: GREEN_33,
    default: GRAY_55,
    altitude: ORANGE_65,
    regulatoryRestricted: RED_65,
    approved: GREEN_50,
    populated: RED_50
  },
  atmil: {
    default: '#A041A0'
  },
  ctr: {
    default: '#071195'
  },
  frb: {
    0: RED_70,
    99: RED_80,
    165: '#F8CA7E',
    197: '#FFE1A9',
    329: '#F6F879',
    stroke: '#333333'
  },
  prohibed: {
    default: RED_50
  },
  restricted: {
    default: '#DC143C'
  },
  supaip: {
    default: '#F400A1'
  },
  psa: {
    fill: '#D41B5C',
    stroke: '#333333'
  },
  obstacles: {
    fill: BLUE_LIGHT_30,
    stroke: BLUE_LIGHT_30
  },
  warnings: {
    fill: '#C80000',
    stroke: '#C80000'
  },
  bigUrban: {
    fill: '#FF9021',
    stroke: '#333333'
  },
  smallUrban: {
    fill: '#FFDB00',
    stroke: '#333333'
  },
  park: {
    fill: '#008443',
    stroke: '#333333'
  },
  reserve: {
    fill: '#00BB5E',
    stroke: '#333333'
  },
  decree: {
    fill: '#BEBB5E',
    stroke: '#333333'
  },
  zsm: {
    fill: '#50FF00',
    stroke: '#333333'
  },
  psaero: {
    stroke: '#FF0000'
  },
  natura: {
    fill: '#ADFF2F',
    stroke: '#333333'
  },
  power: {
    default: '#E4FC5C'
  },
  minorRoads: {
    stroke: YELLOW_45
  },
  warning: {
    stroke: '#FF0000',
    fill: WHITE
  },
  aeromodelling: {
    stroke: '#FF0000',
    fill: WHITE
  },
  rainRadar: {
    overcast: '#88DDEE',
    drizzle: '#0099CC',
    lightRain: '#005588',
    moderateRain: '#FFAA00',
    radarShower: '#FF4400',
    hail: '#990000'
  },
  violation: {
    start: RED_50,
    end: GREEN_40,
    preFlight: ORANGE_50
  },
  icon: {
    panel: BLACK_00,
    over: WHITE
  },
  supervision: {
    lvl0: '#D3D3D3',
    lvl1: '#B3EAF7',
    lvl2: '#B3F7B3',
    lvl3: '#F7E3B3',
    default: WHITE
  },
  classification: {
    highImpact: BOOSTRAP_DANGER,
    mediumImpact: BOOSTRAP_WARNING,
    lowImpact: BOOSTRAP_INFO
  },
  bootstrap: {
    primary: BOOSTRAP_PRIMARY,
    secondary: BOOSTRAP_SECONDARY,
    success: BOOSTRAP_SUCCESS,
    danger: BOOSTRAP_DANGER,
    warning: BOOSTRAP_WARNING,
    info: BOOSTRAP_INFO,
    dark: BOOSTRAP_DARK
  }
}

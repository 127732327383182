import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/axios-auth'
import { abilityPlugin, ability as appAbility } from './ability'
import router from '../router'
import i18n from '@/vue-i18n'

Vue.use(Vuex)

export const ability = appAbility

export const store = new Vuex.Store({
  state: {
    token: null,
    timer: null,
    roles: []
  },
  plugins: [
    abilityPlugin
  ],
  mutations: {
    setAuthToken (state, data) {
      state.token = data.token
    },
    setAuthRoles (state, data) {
      state.roles = data.roles
    },
    clearAuthData (state) {
      state.token = null
      state.roles = []
    },
    setTimer (state, timer) {
      if (state.timer) {
        clearTimeout(state.timer)
      }
      state.timer = timer
    }
  },
  actions: {
    startTimer ({ commit, dispatch }, durationMilliseconds) {
      const timer = setTimeout(() => {
        dispatch('logout')
      }, durationMilliseconds)

      commit('setTimer', timer)
    },
    login ({ commit, dispatch }, authData) {
      return new Promise((resolve, reject) => {
        axios.post('security/login', authData)
          .then(res => {
            const now = new Date()
            const expiredAtM = res.data.expiredAt * 1000
            const duration = expiredAtM - now.getTime()

            localStorage.setItem('token', res.data.token)
            localStorage.setItem('expiredAtM', expiredAtM)
            localStorage.setItem('duration', duration)
            localStorage.setItem('roles', JSON.stringify(res.data.roles))
            localStorage.setItem('name', res.data.name)
            localStorage.setItem('gjrt', res.data.gjrt)
            localStorage.setItem('lang', res.data.lang)
            localStorage.setItem('status', res.data.status)
            localStorage.setItem('supervision', res.data.supervision)
            localStorage.setItem('email', res.data.email)
            localStorage.setItem('airspaceAdmin', res.data.airspaceAdmin)
            localStorage.setItem('airspaceAdminId', res.data.airspaceAdminId)
            localStorage.setItem('homepage', res.data.homepage)

            i18n.locale = res.data.lang

            axios.defaults.headers.common.Authorization = 'Bearer ' + res.data.token

            commit('setAuthToken', { token: res.data.token })
            commit('setAuthRoles', { roles: res.data.roles })

            dispatch('startTimer', duration)

            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    gjrt ({ commit, dispatch }) {
      const gjrt = localStorage.getItem('gjrt')
      if (!gjrt) {
        return
      }

      axios.post('security/gjrt', { gjrt: gjrt })
        .then(res => {
          const duration = parseInt(localStorage.getItem('duration'))
          const now = new Date()
          const expiredAtM = now.getTime() + duration

          localStorage.setItem('token', res.data.token)
          localStorage.setItem('expiredAtM', expiredAtM)
          localStorage.setItem('gjrt', res.data.gjrt)

          axios.defaults.headers.common.Authorization = 'Bearer ' + res.data.token

          commit('setAuthToken', { token: res.data.token })

          dispatch('startTimer', duration)
        })
        .catch(e => {
          dispatch('logout')
        })
    },
    tryAutoLogin ({ commit, dispatch }) {
      let canAutoLogin = true

      const token = localStorage.getItem('token')
      if (!token) {
        return
      }

      const expiredAtM = parseInt(localStorage.getItem('expiredAtM'))
      const now = new Date()
      if (now.getTime() > expiredAtM) {
        canAutoLogin = false
      }

      let roles
      try {
        roles = JSON.parse(localStorage.getItem('roles'))
        if (!roles) {
          canAutoLogin = false
        }
      } catch (e) {
        canAutoLogin = false
      }

      if (!canAutoLogin) {
        dispatch('logout')
        return
      }

      commit('setAuthToken', { token: token })
      commit('setAuthRoles', { roles: roles })

      dispatch('startTimer', expiredAtM - now.getTime())
    },
    logout ({ commit }) {
      delete axios.defaults.headers.common.Authorization

      commit('clearAuthData')

      localStorage.removeItem('token')
      localStorage.removeItem('expiredAtM')
      localStorage.removeItem('duration')
      localStorage.removeItem('roles')
      localStorage.removeItem('name')
      localStorage.removeItem('gjrt')
      localStorage.removeItem('status')
      localStorage.removeItem('email')
      localStorage.removeItem('homepage')

      router.replace({ name: 'login' })
    }
  }
})

<template>
  <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" class="layout-navbar container-p-x">

    <!-- Sidenav toggle -->
    <b-navbar-nav v-if="sidenavToggle && !isNotBackOffice" class="mr-4">
      <a class="nav-item nav-link px-0 ml-2 ml-lg-0" href="javascript:void(0)" @click="toggleSidenav">
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-nav>

    <!-- Left logo (ASD) -->
    <div v-if="!hiddenBrand">
      <img height="50" src="/logo/logo_asd_small.png">
    </div>

    <!-- Right part hidden on low resolution-->
    <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>
    <b-collapse is-nav id="app-layout-navbar">
      <b-navbar-nav class="w-100 d-flex justify-content-between bd-highlight align-items-center">

        <!-- Global menu -->
        <div class="col p-2 bd-highlight text-center">
          <ul class="nav tabs-alt nav-tabs justify-content-center border-0">
            <li v-for="(navbarButton, index) in navbarButtons" :key="index" class="nav-item">
              <router-link custom :to="{ name: navbarButton.to }" :class="isNavButtonActive(navbarButton.to)" v-slot="{ href }">
                <b-dd-item :href="href" class="p-0">{{ $t(navbarButton.label) }}</b-dd-item>
              </router-link>
            </li>
          </ul>
        </div>

        <!-- Drone activity -->
        <div v-if="isDroneActivity" class="px-2 bd-highlight text-right">
          <div class="spinner-grow spinner-grow-sm spinner-rec text-danger" role="status">
            <span class="sr-only">{{ $t("preFlight.takeOff") }}</span>
          </div>
          <app-baseIcon icon='drone' :width="40" :height="40" :colors="[colorTakeOff]"/>
          <!-- TODO : Make me clickable go to the preflights and select the first active -->
        </div>

        <!-- User -->
        <div class="px-2 bd-highlight text-right">
          <div class="float-left">
            <b-nav-item-dropdown>
              <template slot="button-content">
                <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                  <b-avatar variant="secondary" :text="initials"></b-avatar>
                  <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ username }}</span>
                </span>
              </template>

              <router-link custom :to="{ name: 'dashboard'}" v-slot="{ href }">
                <b-dd-item :href="href"><i class="ion ion-md-calculator text-primary"></i> &nbsp; {{ $t("nav.dashboard") }}</b-dd-item>
              </router-link>

              <b-dd-divider />

              <!-- All users -->
              <router-link v-if="!$can('ROLE_SUPERVISION_PILOT') || $can('ROLE_SUPERVISION_ACCOUNT_EDIT')" custom :to="{ name: 'account'}" v-slot="{ href }">
                <b-dd-item :href="href"><i class="ion ion-md-person text-primary"></i> &nbsp; {{ $can('ROLE_SUPERVISION_PILOT') ? $t("nav.parentAccount") : $t("nav.myAccount") }}</b-dd-item>
              </router-link>

              <!-- Airspace Managers -->
              <router-link v-if="$can('ROLE_PREFLIGHTPROCEDURE_EDIT')" custom :to="{ name: 'airspace', params: { id: relatedPilot }}" v-slot="{ href }">
                <b-dd-item :href="href"><i class="ion ion-md-desktop text-primary"></i> &nbsp; {{ $t("nav.myAirspace") }}</b-dd-item>
              </router-link>

              <router-link v-if="$can('ROLE_PROCEDUREAUTOMATION_LIST')" custom :to="{ name: 'procedureAutomations', params: { id: relatedPilot }}" v-slot="{ href }">
                <b-dd-item :href="href"><i class="ion ion-md-switch text-primary"></i> &nbsp; {{ $t("nav.procedureAutomations") }}</b-dd-item>
              </router-link>

              <router-link v-if="$can('ROLE_PROCEDURECLASSIFICATION_LIST')" custom :to="{ name: 'procedureClassifications', params: { id: relatedPilot }}" v-slot="{ href }">
                <b-dd-item :href="href"><i class="ion ion-md-podium text-primary"></i> &nbsp; {{ $t("nav.procedureClassifications") }}</b-dd-item>
              </router-link>

              <!-- Operators -->
              <router-link v-if="$can('ROLE_SUPERVISION_PILOT')" custom :to="{ name: 'editPilot', params: { id: relatedPilot }}" v-slot="{ href }">
                <b-dd-item :href="href"><i class="ion ion-md-person text-primary"></i> &nbsp; {{ $t("nav.myAccount") }}</b-dd-item>
              </router-link>

              <router-link v-if="$can('ROLE_PILOT_LIST')" custom :to="{ name: 'pilots'}" v-slot="{ href }">
                <b-dd-item :href="href"><i class="ion ion-md-headset text-primary"></i> &nbsp; {{ $t("nav.pilots") }}</b-dd-item>
              </router-link>

              <router-link v-if="$can('ROLE_MACHINE_LIST')" custom :to="{ name: 'machines'}" v-slot="{ href }">
                <b-dd-item :href="href"><i class="ion ion-md-paper-plane text-primary"></i> &nbsp; {{ $t("nav.machines") }}</b-dd-item>
              </router-link>

              <b-dd-divider />

              <router-link custom :to="{ name: 'logout'}" v-slot="{ href }">
                <b-dd-item :href="href"><i class="ion ion-md-log-out text-danger"></i> &nbsp; {{ $t("nav.logout") }}</b-dd-item>
              </router-link>
            </b-nav-item-dropdown>
          </div>

          <div class="float-right">
            <div class="ml-4 d-none d-lg-block">
              <img height="50" src="/logo/logo_env_small.png">
            </div>
          </div>
        </div>

      </b-navbar-nav>
    </b-collapse>

  </b-navbar>
</template>

<script>
import { featureMixin } from '@/mixins/FeatureMixin.js'
import BaseIcon from '@/components/Ui/BaseIcon'
import ColorCode from '@/colors.js'

export default {
  name: 'app-layout-navbar',
  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    }
  },
  mixins: [featureMixin],
  components: {
    'app-baseIcon': BaseIcon
  },
  computed: {
    hiddenBrand () {
      return process.env.VUE_APP_HIDE_ASD_BRAND === 'YES'
    },
    isNotBackOffice () {
      return this.$router.currentRoute.name === 'live' ||
        this.$router.currentRoute.name === 'replay' ||
        this.$router.currentRoute.name === 'preflights' ||
        this.$router.currentRoute.name === 'addPreflight' ||
        this.$router.currentRoute.name === 'editPreflight' ||
        this.$router.currentRoute.name === 'procedures' ||
        this.$router.currentRoute.name === 'editProcedure'
    },
    username () {
      return localStorage.getItem('name')
    },
    initials () {
      return localStorage.getItem('name').match(/\b\w/g).join('').toUpperCase()
    },
    relatedPilot () {
      return localStorage.getItem('supervision')
    }
  },
  data () {
    return {
      navbarButtons: [],
      isDroneActivity: false,
      colorTakeOff: ColorCode.preflight.takeOff
    }
  },
  created () {
    this.navbarButtons = this.getFeatures()
  },
  methods: {
    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    },
    getLayoutNavbarBg () {
      return this.layoutNavbarBg
    },
    isNavButtonActive (name) {
      let currentRouteName = this.$router.currentRoute.name

      // Special case management
      switch (currentRouteName) {
        case 'addPreflight':
        case 'editPreflight':
        case 'editPostflight':
          currentRouteName = 'preflights'
          break
        case 'editProcedure':
          currentRouteName = 'procedures'
          break
        default:
          break
      }

      const found = this.navbarButtons.filter(n => n.to === currentRouteName).length !== 0

      return currentRouteName === name || (!found && name === 'dashboard') ? 'nav-link active font-weight-bold' : 'nav-link'
    },
    setDroneActivity (value) {
      this.isDroneActivity = value
    }
  }
}
</script>
<style>
.spinner-rec {
  position: relative;
  display: inline-block;
  top: -15px;
  left: 50px;
}
</style>

const STATUS_NOT_VALIDATED = 'notValidated'
const STATUS_TO_BE_VALIDATED = 'toBeValidated'
const STATUS_VALIDATED = 'validated'
const STATUS_UPDATED = 'updated'
const STATUS_NEARLY_EXPIRED = 'nearlyExpired'
const STATUS_RENEW_VALIDATION = 'renewValidation'
const STATUS_EXPIRED = 'expired'
const STATUS_REJECTED = 'rejected'
const STATUS_NO_NEED_AGREEMENT = 'noNeedAgreement'

export default { STATUS_NOT_VALIDATED, STATUS_TO_BE_VALIDATED, STATUS_VALIDATED, STATUS_UPDATED, STATUS_NEARLY_EXPIRED, STATUS_RENEW_VALIDATION, STATUS_EXPIRED, STATUS_REJECTED, STATUS_NO_NEED_AGREEMENT }

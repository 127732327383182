
import { localStorageMixin } from '@/mixins/LocalStorageMixin.js'
import axios from '@/axios-auth'
import Sources from '@/components/Map/Sources'
import SpotTypes from '@/components/Map/SpotTypes'

export const takeOffMixin = {
  mixins: [localStorageMixin],
  methods: {
    getStorageTakeOffs () {
      const data = this.loadLocalStorage('takeOffs', JSON.stringify([]))
      return JSON.parse(data, JSON.stringify([]))
    },
    isStorageTakeOff (preFlightId = null) {
      const takeOffs = this.getStorageTakeOffs()
      return preFlightId === null ? takeOffs.length !== 0 : takeOffs.filter(t => t.id === preFlightId).length !== 0
    },
    setStorageLanding (preFlightId) {
      let takeOffs = this.getStorageTakeOffs()

      // Save the flight
      const takeOff = takeOffs.filter(t => t.id === preFlightId)
      if (takeOff.length === 1) {
        takeOff[0].last = new Date().getTime()
        this.sendLanding(takeOff[0])
      }

      // Remove and save
      takeOffs = takeOffs.filter(t => t.id !== preFlightId)
      this.saveLocalStorage('takeOffs', JSON.stringify(takeOffs))
    },
    setStorageTakeOff (preFlightId) {
      // Send Traffic - Dissabled
      // navigator.geolocation.getCurrentPosition(this.geolocationSuccess, this.geolocationError)

      const takeOffs = this.getStorageTakeOffs()
      const takeOff = takeOffs.filter(t => t.id === preFlightId)

      if (takeOff.length === 1) {
        // Update
        takeOff[0].last = new Date().getTime()
        this.sendActivityTime(takeOff[0])
      } else {
        // Insert
        const newTakeOff = { id: preFlightId, first: new Date().getTime(), last: new Date().getTime() }
        takeOffs.push(newTakeOff)
        this.sendActivityTime(newTakeOff)
      }

      this.saveLocalStorage('takeOffs', JSON.stringify(takeOffs))
    },
    sendActivityTime (takeOff) {
      axios.post('preflights/takeoff', { preFlightId: takeOff.id })
        .then(() => {
        }).catch(() => {
        })
    },
    sendLanding (takeOff) {
      axios.post('preflights/landing', { preFlightId: takeOff.id })
        .then(() => {
        }).catch(() => {
        })
    },
    geolocationSuccess (position) {
      if (position === undefined) return

      const traffic = {
        ident: localStorage.getItem('name').match(/\b\w/g).join('').toUpperCase(),
        lat: position.coords.latitude.toString(),
        lon: position.coords.longitude.toString(),
        alt: position.coords.altitude,
        spd: position.coords.speed,
        heading: position.coords.heading !== null ? position.coords.heading : 0,
        time: new Date(position.timestamp).toISOString(),
        type: SpotTypes.TYPE_USER,
        source: Sources.SOURCE_MOBILE,
        charge: null,
        charging: null,

        homeHeight: null,
        homeLat: null,
        homeLon: null,
        stationName: null,
        stationLatitude: null,
        stationLongitude: null
      }

      axios.post('traffics/', traffic)
        .then(() => {
        }).catch(() => {
        })
    },
    geolocationError () {
      // Nothing to do
    }
  }
}
